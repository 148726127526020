import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Grid, Card, Typography, Box, Stack, Select } from "@mui/material";
import { FactCheck } from "@mui/icons-material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MemoryIcon from "@mui/icons-material/Memory";
import CachedIcon from "@mui/icons-material/Cached";
import ImageIcon from "@mui/icons-material/Image";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Menu, MenuItem, Tab } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";

// import Modal from "@mui/material/Modal";
import { Checkbox, FormControlLabel } from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { GridArrowDownwardIcon } from "@mui/x-data-grid";
import ViewResultDetails from "./warrantyManagement/warrantyMaster/ViewResultDetails";

const AiOpenModal = ({ show, hideModal }) => {
  const [text, setText] = useState("");
  const maxCharacters = 2000;

  const [textValue, setTextValue] = useState("");
  const [faqEl, setFAQEl] = useState(null);

  const [webFaqE1, setWebFaqE1] = useState(null);
  const [selectedWeb, setSelectedWeb] = useState("All Web");
  const [selectedCardText, setSelectedCardText] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleClickFAQ = (event) => {
    setFAQEl(event.currentTarget);
  };

  const handleCloseFAQ = (value) => {
    setFAQEl(null);
  };

  const handleSelectCard = (selectText, cardNo) => {
    setSelectedCardText(selectText);
    setSelectedCard(cardNo);
    setTextValue(selectText);
  };

  const handleRefreshPrompts = () => {
    setSelectedCardText("");
    setSelectedCard(null);
    setText("");
    setTextValue("");
  };

  const handleClickWebFAQ = (event) => {
    setWebFaqE1(event.currentTarget);
  };

  const handleCloseWebFAQ = (value) => {
    setWebFaqE1(null);
  };

  const handleSelectWebType = (webType) => {
    setSelectedWeb(webType);
    handleCloseWebFAQ(null);
  };

  const handleTextChange = (e) => {
    setText(e.target.textContent);
  };

  const editableRef = useRef(null);
  useEffect(() => {
    if (!isEditing && editableRef.current) {
      editableRef.current.innerText = textValue || "Ask whatever you want...";
    }
  }, [textValue, isEditing]);

  const handleInput = (e) => {
    const currentText = e.target.innerText;
    // Update the ref text only; avoid using state to prevent re-renders while typing
    setText(currentText);
  };

  const handleFocus = () => {
    setIsEditing(true); // Prevent resetting the placeholder
    if (editableRef.current.innerText === "Ask whatever you want...") {
      editableRef.current.innerText = ""; // Clear placeholder
    }
  };

  const handleBlur = () => {
    setIsEditing(false); // Allow placeholder reset if empty
    if (editableRef.current.innerText.trim() === "") {
      editableRef.current.innerText = "Ask whatever you want..."; // Show placeholder on blur if empty
      setTextValue("");
    } else {
      setTextValue(editableRef.current.innerText); // Save entered text on blur
    }
  };
  const textColor = textValue === "" ? "black" : "purple";
  const handleOpenResultModal = () => {
    setShowModal(true);
  };
  return (
    <div>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          {" "}
          <Grid container>
            <div
              className="card p-4  rounded "
              style={{ backgroundColor: "#E5E5E5" }}
            >
              <div className="ml-4">
                <Typography variant="h4" component="p">
                  Hi there,{" "}
                  <span className="bg-blue-white-gradient">Ashok</span>
                </Typography>
                <Typography variant="h4" component="p">
                  What{" "}
                  <span className="bg-blue-white-gradient">
                    would you like to know?
                  </span>
                </Typography>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  Use one of the most common prompts
                </Typography>
                <Typography variant="h6">Or use your own to begin</Typography>
              </div>
              <div className=" mt-3 ">
                <Grid container className="px-4" spacing={2}>
                  <Grid item xs={3}>
                    <div
                      className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                        selectedCard === 1
                          ? "register-products-active-card"
                          : ""
                      }`}
                      style={{ minHeight: "125px" }}
                      onClick={() =>
                        handleSelectCard(
                          "Upload your sales invoice/warranty card to register the warranty",
                          1
                        )
                      }
                    >
                      <div className="font-weight-bold font-size-14">
                        <strong>
                          Upload your sales invoice/warranty card to register
                          the warranty
                        </strong>
                        <p
                          className="mb-0 mt-4"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            left: "10px",
                          }}
                        >
                          <FactCheck fontSize="medium" />
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                        selectedCard === 2
                          ? "register-products-active-card"
                          : ""
                      }`}
                      style={{ minHeight: "125px" }}
                      onClick={() =>
                        handleSelectCard(
                          "Upload your service report to create a warranty claim request",
                          2
                        )
                      }
                    >
                      <div className="font-weight-bold font-size-14">
                        <strong>
                          Upload your service report to create a warranty claim
                          request
                        </strong>
                        <p
                          className="mb-0 mt-4"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            left: "10px",
                          }}
                        >
                          <MailOutlineIcon fontSize="medium" />
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                        selectedCard === 3
                          ? "register-products-active-card"
                          : ""
                      }`}
                      style={{ minHeight: "125px" }}
                      onClick={() =>
                        handleSelectCard(
                          "Summerise product information for me in one paragraph",
                          3
                        )
                      }
                    >
                      <div className="font-weight-bold font-size-14">
                        <strong>
                          Summerise product information for me in one paragraph
                        </strong>
                        <p
                          className="mb-0 mt-4"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            left: "10px",
                          }}
                        >
                          <ChatBubbleOutlineIcon fontSize="medium" />
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                        selectedCard === 4
                          ? "register-products-active-card"
                          : ""
                      }`}
                      style={{ minHeight: "125px" }}
                      onClick={() =>
                        handleSelectCard(
                          "Upload your sales invoice to create an installation request",
                          4
                        )
                      }
                    >
                      <div className="font-weight-bold font-size-14">
                        <strong>
                          Upload your sales invoice to create an installation
                          request
                        </strong>
                        <p
                          className="mb-0 mt-4"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            left: "10px",
                          }}
                        >
                          <MemoryIcon fontSize="medium" />
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div
                className="font-size-16 px-4 mb-2 cursor"
                onClick={handleRefreshPrompts}
              >
                <CachedIcon sx={{ fontSize: "28px" }} />
                <span className="m-1">Refresh Prompts</span>
              </div>
              <Box
                style={{
                  backgroundColor: "white",
                  borderRadius: 5,
                  padding: 2,
                  marginTop: "20px",
                  width: "80%",
                }}
              >
                <div className="d-flex justify-content-between align-items-start py-2">
                  <div className="d-flex" style={{ flex: 1 }}>
                    <span
                      className=""
                      style={{
                        position: "absolute",
                        transform: "translateY(8px)",
                        marginRight: "8px",
                      }}
                    >
                      <AutoFixHighSharpIcon style={{ fontSize: "40px" }} />
                    </span>
                    <div
                      ref={editableRef}
                      contentEditable={true}
                      onInput={handleInput}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{
                        width: "100%",
                        minHeight: "200px",
                        marginBottom: "20px",
                        padding: "10px",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        fontSize: "16px",
                        overflowWrap: "break-word",
                        marginLeft: "48px",
                        color: textColor,
                      }}
                      className="ml-4 text-dark font-weight-bold font-size-14"
                    >
                      {text === "" && textValue === ""
                        ? "Ask whatever you want..."
                        : ""}
                    </div>{" "}
                  </div>
                  <div
                    className="d-flex align-items-center text-primary p-2 bg-grey rounded-pill ml-2 cursor "
                    onClick={handleClickWebFAQ}
                    style={{ marginLeft: "16px" }}
                  >
                    <LanguageOutlinedIcon />
                    <span className="mx-1">{selectedWeb}</span>
                    {/* {selectedWeb} */}
                    <ArrowDropDownIcon />
                  </div>
                  <Menu
                    id="simple-menu"
                    anchorEl={webFaqE1}
                    keepMounted
                    open={Boolean(webFaqE1)}
                    onClose={() => handleCloseWebFAQ(null)}
                  >
                    <MenuItem onClick={() => handleSelectWebType("All Web")}>
                      All Web
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleSelectWebType("Service CPQ")}
                    >
                      Service CPQ
                    </MenuItem>
                    <MenuItem>SAP</MenuItem>
                  </Menu>
                  {/* </div> */}
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2 ">
                  <div className="d-flex align-items-center">
                    <div>
                      {/* <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickFAQ}>
                    <AddCircleIcon /> Add Attachment <KeyboardArrowDownIcon className="small" />
                  </Button>*/}
                      <div className="cursor" onClick={handleClickFAQ}>
                        <AddCircleIcon /> Add Attachment
                      </div>
                      <Menu
                        id="simple-menu"
                        anchorEl={faqEl}
                        keepMounted
                        open={Boolean(faqEl)}
                        onClose={() => handleCloseFAQ(null)}
                      >
                        <MenuItem>
                          <FormControlLabel
                            sx={{ mb: 0, py: 0, px: 0.5 }}
                            control={<Checkbox />}
                            label="Sales Invoice"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            sx={{ mb: 0, py: 0, px: 0.5 }}
                            control={<Checkbox />}
                            label="Service Report"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            sx={{ mb: 0, py: 0, px: 0.5 }}
                            control={<Checkbox />}
                            label="Failure Report"
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            sx={{ mb: 0, py: 0, px: 0.5 }}
                            control={<Checkbox />}
                            label="Warranty Card"
                          />
                        </MenuItem>
                      </Menu>
                    </div>
                    <span className="ml-3 cursor">
                      <ImageIcon /> Use Image
                    </span>
                  </div>
                  <div className="d-flex align-items-center text-dark font-weight-bold font-size-14">
                    {`${text.length}/${maxCharacters}`}
                    <div
                      className="d-flex justify-content-center align-items-center text-white  bg-primary mx-2 rounded"
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      onClick={handleOpenResultModal}
                    >
                      <ArrowForwardIcon fontSize="medium" />
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </Grid>
        </Modal.Body>
      </Modal>

      {showModal && (
        <ViewResultDetails
          show={showModal}
          hideModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default AiOpenModal;
