import React, { useState } from "react";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  ButtonGroup,
  ToggleButton,
  Dropdown,
  Button,
  ToggleButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { GRID_STYLE, selectCustomStyle } from "./common/constants";
import { currencyFormatter } from "./common/utils/currencyFormatter";

const Invoice = () => {
  const [viewInvoices, setViewInvoices] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const [selectedOption, setSelectedOption] = useState("Past 3 Months");

  // Handler to update selected option
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };
  const tabs = [
    { name: "Orders", value: "tab1" },
    { name: "Not Yet Shipped", value: "tab2" },
    { name: "Cancelled Orders", value: "tab3" },
  ];

  const InvoicesColumns = [
    { field: "orderId", headerName: "Order ID", flex: 1, minWidth: 120 },
    { field: "customer", headerName: "Customer", flex: 1 },
    { field: "itemName", headerName: "Order", flex: 1 },
    { field: "deliveryDate", headerName: "Delivery Date", flex: 1 },
    {
      field: "deliveryPricing",
      headerName: "Delivery Pricing",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row?.deliveryPricing),
    },
    {
      field: "status",
      headerName: "Delivery Status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <span
          className={`dispatched-order-status ${
            params.value.toLowerCase() === "reviewed"
              ? "dispatch-reviewed-order"
              : params.value.toLowerCase() === "in revision"
              ? "dispatch-revision-order"
              : "dispatch-approved-order"
          }`}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      flex: 1,
      minWidth: 150,
    },
    // {
    //   field: "paymentTerms",
    //   headerName: "Payment Terms",
    //   flex: 1,
    //   minWidth: 150,
    // },
    // {
    //   field: "billingType",
    //   headerName: "Billing Type",
    //   flex: 1,
    //   minWidth: 150,
    // },
    // {
    //   field: "currency",
    //   headerName: "Currency",
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => setViewInvoices(true)}>
                <EditOutlinedIcon />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];
  const InvoiceRows = [
    {
      id: 1,
      orderId: "AKN12508",
      customer: "Olivera Nules",
      itemName: "Nike Tshirt",
      deliveryDate: "12/04/2024",
      deliveryPricing: 2490,
      status: "In Revision",
      paymentMode: "Credit Card",
      paymentTerms: "Net 45",
      billingType: "Prepaid",
      currency: "EUR",
    },
    {
      id: 2,
      orderId: "SRT32123",
      customer: "Alexandre Selinelli",
      itemName: "Adidas Hat",
      deliveryDate: "22/05/2024",
      deliveryPricing: 1990,
      status: "Reviewed",
      paymentMode: "Bank Transfer",
      paymentTerms: "Net 30",
      billingType: "Postpaid",
      currency: "GBP",
    },
    {
      id: 3,
      orderId: "TML30321",
      customer: "Selman Kalman",
      itemName: "Hummel Sneakers",
      deliveryDate: "08/03/2024",
      deliveryPricing: 8990,
      status: "Approved",
      paymentMode: "Credit Card",
      paymentTerms: "Net 60",
      billingType: "Prepaid",
      currency: "USD",
    },
    {
      id: 4,
      orderId: "KKA45012",
      customer: "David Dorenli",
      itemName: "Puma Shorts",
      deliveryDate: "16/06/2024",
      deliveryPricing: 1990,
      status: "In Revision",
      paymentMode: "Credit Card",
      paymentTerms: "Net 30",
      billingType: "Postpaid",
      currency: "EUR",
    },
    {
      id: 5,
      orderId: "NNP23421",
      customer: "Marcus Genihales",
      itemName: "Nike Glove",
      deliveryDate: "24/04/2021",
      deliveryPricing: 3990,
      status: "Reviewed",
      paymentMode: "Bank Transfer",
      paymentTerms: "Net 45",
      billingType: "Prepaid",
      currency: "GBP",
    },
    {
      id: 6,
      orderId: "TTS23089",
      customer: "Kemal Solomon",
      itemName: "Nike Hat",
      deliveryDate: "14/04/2024",
      deliveryPricing: 2990,
      status: "Approved",
      paymentMode: "Bank Transfer",
      paymentTerms: "Net 60",
      billingType: "Postpaid",
      currency: "USD",
    },
    {
      id: 7,
      orderId: "KLB24010",
      customer: "Henry Kweale",
      itemName: "Adidas Sneakers",
      deliveryDate: "12/05/2024",
      deliveryPricing: 9990,
      status: "In Revision",
      paymentMode: "Credit Card",
      paymentTerms: "Net 30",
      billingType: "Prepaid",
      currency: "GBP",
    },
    {
      id: 8,
      orderId: "ABL90821",
      customer: "Hanna Marina",
      itemName: "Puma Bag",
      deliveryDate: "01/06/2024",
      deliveryPricing: 3990,
      status: "Reviewed",
      paymentMode: "PayPal",
      paymentTerms: "Net 45",
      billingType: "Postpaid",
      currency: "USD",
    },
    {
      id: 9,
      orderId: "HJS09213",
      customer: "Stevan Kolman",
      itemName: "Nike Bag",
      deliveryDate: "01/03/2024",
      deliveryPricing: 4990,
      status: "Approved",
      paymentMode: "Bank Transfer",
      paymentTerms: "Net 60",
      billingType: "Prepaid",
      currency: "EUR",
    },
    {
      id: 10,
      orderId: "HRS12040",
      customer: "Selen Selman",
      itemName: "Hummel Hat",
      deliveryDate: "12/06/2021",
      deliveryPricing: 1490,
      status: "In Revision",
      paymentMode: "Bank Transfer",
      paymentTerms: "Net 30",
      billingType: "Postpaid",
      currency: "USD",
    },
  ];

  return (
    <div style={{ minHeight: "884px" }}>
      <h4 className="font-weight-600 mb-3 mt-4">Deliveries </h4>
      <div className="my-4">
        <div className="d-flex  justify-content-between">
          <div className=" align-items-center m-0">
            <div
              className={`transaction-search-box d-flex align-items-center mr-3 my-2 border-radius-6`}
              style={{ position: "relative" }}
            >
              <div className="transaction-search customize">
                <input
                  className="transaction-input-sleact pr-1"
                  type="text"
                  placeholder="Search Invoices"
                  // value={inputRegion}
                  autoComplete="off"
                  style={{ width: "300px" }}
                  // onChange={handleDropDownSearchForRegion}
                />
                <div
                  className="btn bg-primary text-white cursor"
                  // onClick={handleSearchForRegion}
                >
                  <span className="mr-2">
                    <SearchIcon />
                  </span>
                  SEARCH
                </div>
                {
                  // <ul
                  //     className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                  //     id="style"
                  // >
                  //     {inputRegion.length !== 0 && searchedDropDownRegion.length === 0 &&
                  //         (
                  //             <li className="list-group-item">
                  //                 No
                  //                 Result
                  //                 found
                  //             </li>
                  //         )}
                  //     {inputRegion.length !== 0 && searchedDropDownRegion.length !== 0 && searchedDropDownRegion.map((currentItem, j) =>
                  //     (
                  //         <li
                  //             className="list-group-item"
                  //             key={j}
                  //             onClick={() =>
                  //                 handleSelectDropdownItemForRegion(
                  //                     currentItem
                  //                 )
                  //             }
                  //         >
                  //             {
                  //                 currentItem["city"]
                  //             }
                  //         </li>
                  //     )
                  //     )}
                  // </ul>
                }
              </div>
            </div>
          </div>
          <div className="mr-3 my-2">
            <button className="btn transparent">
              <FilterListIcon /> Filters
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between  my-3">
          <ButtonGroup
            className="me-3"
            style={{
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
              padding: "8px",
            }}
          >
            {tabs.map((tab, idx) => (
              <Button
                key={tab.value}
                type="button"
                variant={radioValue === tab.value ? "light" : "outline-light"}
                onClick={() => setRadioValue(tab.value)}
                style={{
                  borderRadius:
                    idx === 0
                      ? "8px 0 0 8px"
                      : idx === tabs.length - 1
                      ? "0 8px 8px 0"
                      : "0",
                  fontWeight: radioValue === tab.value ? "bold" : "normal",
                  color: radioValue === tab.value ? "#000" : "#333",
                  backgroundColor:
                    radioValue === tab.value ? "#fff" : "transparent",
                  padding: "10px 12px",
                  fontSize: "15px",
                  margin: "0",
                  lineHeight: "1.2",
                  border: "none",
                  boxShadow:
                    radioValue === tab.value
                      ? "0px 0px 5px rgba(0,0,0,0.2)"
                      : "none",
                }}
              >
                {tab.name}
              </Button>
            ))}
          </ButtonGroup>
          <Dropdown
            className="align-self-start"
            style={{ position: "relative" }}
          >
            <Dropdown.Toggle
              variant="outline-light"
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "8px",
                color: "#333",
                padding: "8px",
              }}
            >
              {selectedOption}{" "}
              <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ selectCustomStyle }}>
              <Dropdown.Item onClick={() => handleSelect("Past 6 Months")}>
                Past 6 Months
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect("Past 3 Months")}>
                Past 3 Months
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect("Past 2 Months")}>
                Past 2 Months
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect("Past 1 Month")}>
                Past 1 Month
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <DataGrid
        rows={InvoiceRows}
        columns={InvoicesColumns}
        autoHeight
        sx={GRID_STYLE}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
      />
    </div>
  );
};

export default Invoice;
