import React, { useEffect, useState } from "react";

import { Divider, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { deliveryTypeOptions, plantByOptions, prReqQObj, sippingConditionOptions } from "../warehouseConstents";
import { FONT_STYLE, FONT_STYLE_SELECT, GRID_STYLE } from "pages/common/constants";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { PURCHASE_REQUISITION_RECEIPT_MASTER_URL } from "services/CONSTANTS";
import { API_SUCCESS } from "services/ResponseCode";
import Moment from "react-moment";
import { ReadOnlyField } from "pages/common/ReadOnlyField";
import LoadingProgress from "pages/common/Loader";

const AddUpdatePR = ({ show, hideModal, handleSnack, partsOrderObj, pendingParts = [], recordId = null }) => {
  const [prRecordObj, setPrRecordObj] = useState({ ...prReqQObj, orderNumber: partsOrderObj?.partOrderNumber || "" });
  const [prId, setPrId] = useState(null);

  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [shipmentItemsData, setShipmentItemsData] = useState([...pendingParts]);

  useEffect(() => {
    if (recordId) {
      getPRDetails(recordId);
    }
  }, [recordId]);

  // get the sto details |Shipment header details
  const getPRDetails = async (recordId) => {
    setViewModeOn(true);
    setLoading(true);
    const rUrl = `${PURCHASE_REQUISITION_RECEIPT_MASTER_URL}/${recordId}`;
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          // find the delivery type
          const _deliveryType = deliveryTypeOptions.find((item) => item.value === responseData?.deliveryType);

          // find the shipping condition
          const _shippingCondition = sippingConditionOptions.find((item) => item.value === responseData?.returnType);

          // find the sender plant
          const _senderPlant = plantByOptions.find((item) => item.value === response?.senderPlant);

          // find the receiver plant
          const _receiverPlant = plantByOptions.find((item) => item.value === response?.receiverPlant);

          setPrRecordObj({
            ...responseData,
            // deliveryType: _deliveryType || deliveryTypeOptions[0],
            deliveryType: _deliveryType || "",
            shippingCondition: _shippingCondition || "",
            // senderPlant: _senderPlant || plantByOptions[0],
            senderPlant: _senderPlant || "",
            receiverPlant: _receiverPlant || plantByOptions[1],
          });

          setShipmentItemsData(responseData["prPartsQuantities"]);

          setPrId(responseData.prId);
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
        handleSnack("error", "Error fetching STO details");
      }
    );
  };

  // change the input text value
  const handleChangeInputText = (e) => {
    const { name, value } = e.target;
    setPrRecordObj({ ...prRecordObj, [name]: value });
  };

  // change the select option
  const handleSelectValueChange = (e, keyName) => {
    setPrRecordObj({ ...prRecordObj, [keyName]: e });
  };

  const columns = [
    {
      field: "partsNumber",
      headerName: "Part Number",
      flex: 1,
    },
    {
      field: "partsDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity Ordered",
      flex: 1,
    },
  ];

  // // click on Submit button >> Old
  // const handleSubmit = () => {
  //   const rUrl = PURCHASE_REQUISITION_RECEIPT_MASTER_URL;
  //   const _shipmentParts = shipmentItemsData.map((row) => row.shipmentPartId);
  //   const rObj = {
  //     ...prRecordObj,

  //     // prId: prRecordObj?.stoNumber,
  //     rmaReason: prRecordObj?.referenceDescription,
  //     rmaType: prRecordObj.orderNumber,
  //     rmaNumber: prRecordObj?.referenceId,
  //     returnType: prRecordObj.shippingCondition?.value || "INTRA_COMPANY",
  //     senderLocation: prRecordObj?.senderStorageLocation,
  //     senderPlant: prRecordObj?.senderPlant?.value || "",
  //     shipmentParts: [..._shipmentParts],
  //   };

  //   // deliveryType: _deliveryType || deliveryTypeOptions[0],

  //   if (prId) {
  //     callPutApi(null, `${rUrl}/${prId}`, rObj, (response) => {
  //       if (response.status === API_SUCCESS) {
  //         handleSnack("success", "PR updated successfully.");
  //         setViewModeOn(true);
  //       } else {
  //         handleSnack("info", "Something went wrong.");
  //       }
  //     });
  //   } else {
  //     const _stoNumber = "PR" + (Math.floor(Math.random() * 9000) + 1000);
  //     callPostApi(null, `${rUrl}`, { ...rObj, returnNumber: _stoNumber }, (response) => {
  //       if (response.status === API_SUCCESS) {
  //         const responseData = response.data;
  //         handleSnack("success", "PR created successfully.");
  //         // handleSnack("info", "STO accepted in ERP & Updated in GR.");
  //         setPrRecordObj({ ...prRecordObj, stoNumber: responseData._stoNumber });
  //         setPrId(responseData.prId);
  //         setViewModeOn(true);
  //       } else {
  //         handleSnack("info", "Something went wrong.");
  //       }
  //     });
  //   }
  // };

  // click on Submit button
  const handleSubmit = () => {
    const rUrl = PURCHASE_REQUISITION_RECEIPT_MASTER_URL;
    const rObj = {
      ...prRecordObj,
      deliveryType: prRecordObj?.deliveryType?.value || "",
      shippingCondition: prRecordObj?.shippingCondition?.value || "",
      senderPlant: prRecordObj?.senderPlant?.value || "",
      receiverPlant: prRecordObj?.receiverPlant?.value || "",
    };
    if (prId) {
      callPutApi(null, `${rUrl}/${prId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          handleSnack("success", "PR updated successfully.");
          setViewModeOn(true);
        } else {
          handleSnack("info", "Something went wrong.");
        }
      });
    } else {
      const _prPartsQuantities = [];
      if (shipmentItemsData.length > 0) {
        shipmentItemsData.map((item) =>
          _prPartsQuantities.push({
            partsNumber: item.partsNumber,
            partsDescription: item?.partsDescription,
            quantity: item?.quantity,
          })
        );
      }
      const _shipmentParts = shipmentItemsData.map((row) => row.shipmentPartId);
      const _stoNumber = "PR" + (Math.floor(Math.random() * 9000) + 1000);
      callPostApi(null, `${rUrl}`, { ...rObj, prPartsQuantities: _prPartsQuantities }, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleSnack("success", "PR created successfully.");
          setPrRecordObj({
            ...responseData,
            deliveryType: prRecordObj?.deliveryType,
            shippingCondition: prRecordObj?.shippingCondition,
            senderPlant: prRecordObj?.senderPlant,
            receiverPlant: prRecordObj?.receiverPlant,
          });
          // setPrRecordObj({ ...prRecordObj, prNumber: responseData._stoNumber });
          setPrId(responseData.prId);
          setViewModeOn(true);
        } else {
          handleSnack("info", "Something went wrong.");
        }
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <div className="d-flex justify-content-between align-items-center px-3 my-2">
          <h4 className="font-weight-500">Purchase Requisition Details</h4>
          {prId && (
            <button className="btn border-primary text-primary py-2" onClick={() => setViewModeOn(false)}>
              Edit
            </button>
          )}
        </div>
        <Divider />
        <Modal.Body>
          {loading ? (
            <LoadingProgress />
          ) : (
            <>
              <div className="card border px-3 py-2">
                {viewModeOn ? (
                  <div className="row mt-2">
                    <ReadOnlyField label="REQUISITION NUMBER" value={prRecordObj?.prNumber} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="ORDER NUMBER" value={prRecordObj?.orderNumber} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="REFERENCE DESCRIPTION" value={prRecordObj?.referenceDescription} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="DELIVERY TYPE" value={prRecordObj?.deliveryType?.label} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="SHIPPPING CONDITION" value={prRecordObj?.shippingCondition?.label} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="SENDER STORAGE LOCATION" value={prRecordObj?.senderStorageLocation} className="col-md-3 col-sm-3" />
                    <ReadOnlyField
                      label="SHIPPED ON"
                      value={prRecordObj?.shippedOn ? <Moment format="DD/MM/YYYY">{prRecordObj?.shippedOn}</Moment> : "NA"}
                      className="col-md-3 col-sm-3"
                    />
                    <ReadOnlyField label="TRACKING NUMBER" value={prRecordObj?.trackingNumber} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="RECEIVER PLANT" value={prRecordObj?.receiverPlant?.label} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="RECEIVER LOCATION" value={prRecordObj?.receiverLocation} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="RECEIVER ADDRESS" value={prRecordObj?.receiverAddress} className="col-md-3 col-sm-3" />
                  </div>
                ) : (
                  <div className="row input-fields">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">REQUISITION NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={prRecordObj?.prNumber}
                          name="prNumber"
                          placeholder="Requisition Number"
                          onChange={handleChangeInputText}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ORDER NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={prRecordObj?.orderNumber}
                          name="orderNumber"
                          placeholder="Order Number"
                          onChange={handleChangeInputText}
                          disabled={recordId ? true : false}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">REFERENCE DESCRIPTION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={prRecordObj?.referenceDescription}
                          name="referenceDescription"
                          placeholder="Reference Description"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">DELIVERY TYPE</label>
                        <Select
                          className="text-primary"
                          value={prRecordObj?.deliveryType}
                          onChange={(e) => handleSelectValueChange(e, "deliveryType")}
                          options={deliveryTypeOptions}
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SHIPPPING CONDITION</label>
                        <Select
                          className="text-primary"
                          value={prRecordObj?.shippingCondition}
                          onChange={(e) => handleSelectValueChange(e, "shippingCondition")}
                          options={sippingConditionOptions}
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SENDER STORAGE LOCATION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={prRecordObj?.senderStorageLocation}
                          name="senderStorageLocation"
                          placeholder="Sender Storage Location"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SHIPPED ON</label>
                        <div className="align-items-center date-box">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                              inputFormat="dd/MM/yyyy"
                              className="form-controldate border-radius-10"
                              closeOnSelect
                              value={prRecordObj?.shippedOn}
                              onChange={(e) => setPrRecordObj({ ...prRecordObj, shippedOn: e })}
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">TRACKING NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={prRecordObj?.trackingNumber}
                          name="trackingNumber"
                          placeholder="Tracking Number"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">RECEIVER PLANT</label>
                        <Select
                          className="text-primary"
                          value={prRecordObj?.receiverPlant}
                          onChange={(e) => handleSelectValueChange(e, "receiverPlant")}
                          options={plantByOptions}
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">RECEIVER LOCATION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={prRecordObj?.receiverLocation}
                          name="receiverLocation"
                          placeholder="Receiver Location"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">RECEIVER ADDRESS</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={prRecordObj?.receiverAddress}
                          name="receiverAddress"
                          placeholder="Receiver Address"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="card border px-3 py-3">
                <h5>Products</h5>
                <DataGrid
                  sx={GRID_STYLE}
                  getRowId={(row) => row.prPartsId}
                  rows={shipmentItemsData}
                  autoHeight
                  columns={columns}
                  // columns={partsColumns}
                  editMode="row"
                  // paginationMode="server"
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                />
              </div>
              <div className="row px-2" style={{ justifyContent: "right" }}>
                <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                  Close
                </button>
                {!viewModeOn && (
                  <button className="btn bg-primary text-white mx-2" onClick={handleSubmit}>
                    Submit
                  </button>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUpdatePR;
