import React, { useEffect, useState } from "react";
import SelectFilter from "react-select";
import { Link } from "react-router-dom";

import $ from "jquery";

import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import FilterListIcon from "@mui/icons-material/FilterList";

import CustomizedSnackbar from "../shared/CustomSnackBar";
import { equipmentSearch } from "../services/searchServices";
import { callGetApi } from "../services/ApiCaller";
import {
  CLAIM_MASTER_URL,
  CLAIM_ORDER_MASTER_URL,
  WARRANTY_MASTER_URL,
} from "../services/CONSTANTS";
import { API_SUCCESS } from "../services/ResponseCode";
import {
  GRID_STYLE,
  INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE,
  selectCustomStyle,
} from "./common/constants";
import ClaimEditModal from "./warrantyManagement/claimMaster/ClaimEditModal";
import {
  warrantyCategoryOptions,
  warrantyRequestObj,
  warrantyStatusOptions,
  warrantyUnitOptions,
} from "./warrantyManagement/warrantyManagementConstants";
import ClaimRequestModal from "./warrantyManagement/claimMaster/ClaimRequestModal";
import PartIcons from "../assets/icons/png/PartIcons.png";
import Select from "react-select";
import {
  ButtonGroup,
  ToggleButton,
  Dropdown,
  Button,
  ToggleButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const ClaimHistoryMaster = () => {
  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  const [warrantyRecord, setWarrantyRecord] = useState({
    ...warrantyRequestObj,
  });

  const [selectedValue, setSelectedValue] = useState({
    label: "Claims",
    value: "CLAIMS",
  });

  const [loading, setLoading] = useState(false);
  const [claimRecord, setClaimRecord] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [openClaimEditModal, setOpenClaimEditModal] = useState(false);
  const [openClaimRequestModal, setOpenClaimRequestModal] = useState(false);

  const [claimRecordDetail, setClaimRecordDetail] = useState(null);
  const [claimRecordId, setClaimRecordId] = useState(null);
  const [claimOrderId, setClaimOrderId] = useState(null);
  const [evaluationId, setEvaluationId] = useState(null);
  const [assesstmentId, setAssesstmentId] = useState(null);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  const [claimPageno, setClaimPageno] = useState(0);
  const [claimPageSize, setClaimPageSize] = useState(10);
  const [radioValue, setRadioValue] = useState("1");
  const [selectedOption, setSelectedOption] = useState("Past 3 Months");

  // Handler to update selected option
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };
  const tabs = [
    { name: "Orders", value: "tab1" },
    { name: "Not Yet Shipped", value: "tab2" },
    { name: "Cancelled Orders", value: "tab3" },
  ];

  useEffect(() => {
    if (selectedValue?.value === "CLAIMS") {
      fetchClaimRecords();
    } else if (selectedValue?.value === "ORDERS") {
      // fetchOrderRecords();
    }
  }, [claimPageSize, claimPageno, selectedValue]);

  const fetchClaimRecords = () => {
    setLoading(true);
    const rUrl = `${CLAIM_ORDER_MASTER_URL}?pageNumber=${claimPageno}&pageSize=${claimPageSize}&sortColumn=preparedOn&orderBY=DESC`;
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          if (responseData.length === 0) {
            setLoading(false);
          } else {
            setClaimRecord(responseData);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleChangeSelectValue = (e) => {
    setSelectedValue(e);
  };

  //  handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // select search category
  const handleSearchCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  const handleInputSearch = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    let searchString =
      tempArray[id].selectCategory.value + "~" + e.target.value;
    if (tempArray[id].selectCategory.value && e.target.value) {
      equipmentSearch(searchString)
        .then((res) => {
          obj.selectOptions = res;
          tempArray[id] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${id}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = e.target.value;
  };

  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  //
  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr =
          `field_name=` +
          item.selectCategory.key +
          "&field_value=" +
          item.inputSearch;
        // encodeURI('"' + item.inputSearch + '"');
        // searchStr =
        //   item.selectCategory.key +
        //   ":" +
        //   encodeURI('"' + item.inputSearch + '"');
      } else if (
        item.selectCategory.value &&
        item.inputSearch &&
        item.selectOperator.value
      ) {
        searchStr =
          searchStr +
          " " +
          item.selectOperator.value +
          " " +
          item.selectCategory.value +
          ":" +
          encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const rUrl = `${CLAIM_MASTER_URL}/search-by-fields?${searchStr}`;
        callGetApi(
          null,
          rUrl,
          (response) => {
            if (response.status === API_SUCCESS) {
              const responseData = response.data;
              if (responseData.length === 0) {
                handleSnack("info", INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE);
                setLoading(false);
              } else {
                setClaimRecord(responseData);
                setLoading(false);
              }
            } else {
              handleSnack("info", INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE);
              setLoading(false);
            }
          },
          (error) => {
            setLoading(false);
            handleSnack(
              "error",
              "Error occurred while fetching Claim History!"
            );
          }
        );
        // const res = await equipmentSearch(searchStr);
        // setMasterData(res);
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching Claim history!");
    }
  };

  const claimColumns = [
    {
      field: "claimOrderNumber",
      headerName: "Claim Number",
      flex: 1,
    },
    {
      field: "claimStatus",
      headerName: "Claim Status",
      flex: 1,
      renderCell: (params) => (
        <div>
          <span className="green-dot px-2 mx-2"> </span>
          {" Closed"}
        </div>
      ),
    },
    {
      field: "claimType",
      headerName: "Claim Type",
      flex: 1,
    },
    {
      field: "preparedOn",
      headerName: "Claim Date",
      flex: 1,
    },
    {
      field: "replacement",
      headerName: "Replacement",
      flex: 1,
      renderCell: ({ row }) => (
        <div style={{ fontWeight: "bold" }}>
          {row.replacement ? "Yes" : "No"}
        </div>
      ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div
                className=" cursor"
                onClick={() => handleEditClaimDetails(row)}
              >
                <EditOutlinedIcon />
              </div>
            }
            label="View"
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={
          //     <div className="cursor">
          //       <Tooltip title="View">
          //         <div className="btn btn-primary" onClick={() => handleEditClaimDetails(row)}><img src={PartIcons} alt="Assign" /> View</div>
          //         {/* <EditOutlinedIcon
          //           onClick={() => handleEditClaimDetails(row)}
          //         /> */}
          //       </Tooltip>
          //     </div>
          //   }
          //   label="Edit"
          //   className="textPrimary"
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  const orderColumns = [
    {
      field: "claimOrderNumber",
      headerName: "Order Number",
      flex: 1,
    },
    {
      field: "claimStatus",
      headerName: "Order Status",
      flex: 1,
      renderCell: ({ row }) => (
        <div>
          <span className="green-dot px-2 mx-2"> </span>
          {" Closed"}
        </div>
      ),
    },
    {
      field: "claimType",
      headerName: "Order Type",
      flex: 1,
    },
    {
      field: "preparedOn",
      headerName: "Order Date",
      flex: 1,
    },
    {
      field: "replacement",
      headerName: "Replacement",
      flex: 1,
      renderCell: (row) => (
        <div style={{ fontWeight: "bold" }}>
          {row.replacement ? "Yes" : "No"}
        </div>
      ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div
                className=" cursor"
                onClick={() => handleEditClaimDetails(row)}
              >
                <EditOutlinedIcon />
              </div>
            }
            label="View"
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={
          //     <div className="cursor">
          //       <Tooltip title="View">
          //         <div className="btn btn-primary" onClick={() => handleEditClaimDetails(row)}><img src={PartIcons} alt="Assign" /> View</div>
          //         {/* <EditOutlinedIcon
          //           onClick={() => handleEditClaimDetails(row)}
          //         /> */}
          //       </Tooltip>
          //     </div>
          //   }
          //   label="Edit"
          //   className="textPrimary"
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  // claim table page no and size change
  const handleClaimPaginationChange = (pageNo, rowsPerPage) => {
    setPage(pageNo);
    setPageSize(rowsPerPage);
  };

  // edit Claim Details
  const handleEditClaimDetails = (row) => {
    const claimId = row["claimId"];
    const warrantyId = row["warrantyId"];
    if (warrantyId) {
      const rUrl = `${WARRANTY_MASTER_URL}/${warrantyId}`;
      callGetApi(null, rUrl, (response) => {
        if (response.status === API_SUCCESS) {
          const { installerDetails, customerDetails, ...restData } =
            response.data;
          const responseData = response.data;

          // get category key value pairs
          const _category = warrantyCategoryOptions.find(
            (obj) => obj.value === responseData.category
          );

          // get unit key value pairs
          const _unit = warrantyUnitOptions.find(
            (obj) => obj.value === responseData.unit
          );

          // get status key value pairs
          const _warrantyStatus = warrantyStatusOptions.find(
            (obj) => obj.value === responseData.warrantyStatus
          );

          // set warranty details
          setWarrantyRecord({
            ...responseData,
            category: _category || "",
            // basis: _basis || "",
            unit: _unit || "",
            warrantyStatus: _warrantyStatus || "",
          });
          // } else {
          //   handleSnack("error", "Something went wrong");
        }
      });
    }
    setClaimRecordDetail(row);
    setClaimRecordId(claimId);
    setOpenClaimEditModal(true);
    // handleOpenClaimEditModal();
  };

  //
  const handleOpenClaimRequestModal = () => {
    setOpenClaimEditModal(false);
    setOpenClaimRequestModal(true);
  };

  return (
    <>
      <CustomizedSnackbar
        handleClose={handleSnackBarClose}
        open={openSnack}
        severity={severity}
        message={snackMessage}
      />
      <div style={{ minHeight: "884px" }}>
        <div className="d-flex justify-content-between align-items-center mb-0 mt-4 mb-3">
          <h4 className="font-weight-600 ">
            {selectedValue?.label || "Claims"}
          </h4>
        </div>
        <div className="my-4">
          <div className="d-flex  justify-content-between">
            <div className=" align-items-center m-0">
              <div
                className={`transaction-search-box d-flex align-items-center mr-3 my-2 border-radius-6`}
                style={{ position: "relative" }}
              >
                <div className="transaction-search customize">
                  <input
                    className="transaction-input-sleact pr-1"
                    type="text"
                    placeholder="Search Claims"
                    // value={inputRegion}
                    autoComplete="off"
                    style={{ width: "300px" }}
                    // onChange={handleDropDownSearchForRegion}
                  />
                  <div
                    className="btn bg-primary text-white cursor"
                    // onClick={handleSearchForRegion}
                  >
                    <span className="mr-2">
                      <SearchIcon />
                    </span>
                    SEARCH
                  </div>
                  {
                    // <ul
                    //     className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                    //     id="style"
                    // >
                    //     {inputRegion.length !== 0 && searchedDropDownRegion.length === 0 &&
                    //         (
                    //             <li className="list-group-item">
                    //                 No
                    //                 Result
                    //                 found
                    //             </li>
                    //         )}
                    //     {inputRegion.length !== 0 && searchedDropDownRegion.length !== 0 && searchedDropDownRegion.map((currentItem, j) =>
                    //     (
                    //         <li
                    //             className="list-group-item"
                    //             key={j}
                    //             onClick={() =>
                    //                 handleSelectDropdownItemForRegion(
                    //                     currentItem
                    //                 )
                    //             }
                    //         >
                    //             {
                    //                 currentItem["city"]
                    //             }
                    //         </li>
                    //     )
                    //     )}
                    // </ul>
                  }
                </div>
              </div>
            </div>
            <div className="mr-3 my-2">
              <Select
                // className="customselect1"
                className="border-primary text-primary"
                id="custom"
                value={selectedValue}
                // placeholder=" + Create"
                styles={selectCustomStyle}
                options={[
                  { label: "Claims", value: "CLAIMS" },
                  { label: "Orders", value: "ORDERS" },
                ]}
                onChange={handleChangeSelectValue}
              />
              {/* <button className="btn transparent">
                <FilterListIcon /> Filters
              </button> */}
            </div>
          </div>
          <div className="d-flex justify-content-between  my-3">
            <ButtonGroup
              className="me-3"
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
                padding: "8px",
              }}
            >
              {tabs.map((tab, idx) => (
                <Button
                  key={tab.value}
                  type="button"
                  variant={radioValue === tab.value ? "light" : "outline-light"}
                  onClick={() => setRadioValue(tab.value)}
                  style={{
                    borderRadius:
                      idx === 0
                        ? "8px 0 0 8px"
                        : idx === tabs.length - 1
                        ? "0 8px 8px 0"
                        : "0",
                    fontWeight: radioValue === tab.value ? "bold" : "normal",
                    color: radioValue === tab.value ? "#000" : "#333",
                    backgroundColor:
                      radioValue === tab.value ? "#fff" : "transparent",
                    padding: "10px 12px",
                    fontSize: "15px",
                    margin: "0",
                    lineHeight: "1.2",
                    border: "none",
                    boxShadow:
                      radioValue === tab.value
                        ? "0px 0px 5px rgba(0,0,0,0.2)"
                        : "none",
                  }}
                >
                  {tab.name}
                </Button>
              ))}
            </ButtonGroup>
            <Dropdown
              className="align-self-start"
              style={{ position: "relative" }}
            >
              <Dropdown.Toggle
                variant="outline-light"
                style={{
                  backgroundColor: "#f0f0f0",
                  borderRadius: "8px",
                  color: "#333",
                  padding: "8px",
                }}
              >
                {selectedOption}{" "}
                <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ selectCustomStyle }}>
                <Dropdown.Item onClick={() => handleSelect("Past 6 Months")}>
                  Past 6 Months
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSelect("Past 3 Months")}>
                  Past 3 Months
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSelect("Past 2 Months")}>
                  Past 2 Months
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSelect("Past 1 Month")}>
                  Past 1 Month
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <DataGrid
          loading={loading}
          rows={claimRecord}
          columns={
            selectedValue?.value === "CLAIMS" ? claimColumns : orderColumns
          }
          page={claimPageno}
          autoHeight
          pageSize={claimPageSize}
          sx={GRID_STYLE}
          onPageChange={(newPage) =>
            // handleClaimPaginationChange(newPage, pageSize)
            setClaimPageno(newPage)
          }
          onPageSizeChange={(newPageSize) =>
            // handleClaimPaginationChange(page, newPageSize)
            setClaimPageSize(newPageSize)
          }
          rowCount={34}
          rowsPerPageOptions={[10, 20, 50]}
          paginationMode="server"
          disableRowSelectionOnClick
          getRowId={(row) => row.claimId}
        />
      </div>

      {openClaimEditModal && (
        <ClaimEditModal
          show={openClaimEditModal}
          hideModal={() => setOpenClaimEditModal(false)}
          warrantyRecord={warrantyRecord}
          handleSnack={handleSnack}
          claimRecordId={claimRecordId}
          handleOpenClaimRequestModal={handleOpenClaimRequestModal}
        />
      )}

      {openClaimRequestModal && (
        <ClaimRequestModal
          show={openClaimRequestModal}
          hideModal={() => setOpenClaimRequestModal(false)}
          handleSnack={handleSnack}
          claimRecordDetail={claimRecordDetail}
          claimOrderId={claimOrderId}
          setClaimOrderId={setClaimOrderId}
          claimRecordId={claimRecordId}
          assesstmentId={assesstmentId}
          setAssesstmentId={setAssesstmentId}
          evaluationId={evaluationId}
          setEvaluationId={setEvaluationId}
        />
      )}
    </>
  );
};

export default ClaimHistoryMaster;
