import React, { useState } from "react";

import { DataGrid } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";

import { API_SUCCESS } from "services/ResponseCode";
import { callPostApi, callPutApi } from "services/ApiCaller";
import { WAREHOUSE_GOODS_RECEIPT_MASTER_URL } from "services/CONSTANTS";

const GRSearchPartListModal = ({
  show,
  hideModal,
  masterData = [],
  setRelatedPartsRecords,
  handleSnack,
  recordId,
  setRecordId,
  recordObj,
  setRecordObj,
}) => {
  const [selectedMasterData, setSelectedMasterData] = useState([]);

  // Select parts to add
  const onRowsSelectionHandler = (ids) => {
    setSelectedMasterData([]);
    const selectedRowsData = ids.map((id) => masterData.find((row) => row.id === id));

    setSelectedMasterData(selectedRowsData);
  };

  // add update the GR
  const handleAddUpdateGR = (rowsData = []) => {
    try {
      return new Promise((resolve, reject) => {
        const rUrl = WAREHOUSE_GOODS_RECEIPT_MASTER_URL;
        const rObj = {
          ...recordObj,
          deliveryType: recordObj?.deliveryType?.value || "",
          shippingCondition: recordObj?.shippingCondition?.value || "",
          senderPlant: recordObj?.senderPlant?.value || "",
          receiverPlant: recordObj?.receiverPlant?.value || "",
          grPartsQuantities: [...rowsData],
        };
        if (recordId) {
          callPutApi(null, `${rUrl}/${recordId}`, rObj, (response) => {
            if (response.status === API_SUCCESS) {
              const responseData = response.data;
              setRecordObj({
                ...responseData,
                deliveryType: recordObj?.deliveryType || "",
                shippingCondition: recordObj?.shippingCondition || "",
                senderPlant: recordObj?.senderPlant || "",
                receiverPlant: recordObj?.receiverPlant || "",
              });
              setRelatedPartsRecords([...responseData["grPartsQuantities"]]);

              resolve(response);
              //   handleSnack("success", "GR updated successfully.");
            } else {
              handleSnack("info", "Something went wrong.");
              resolve(response);
            }
          });
        } else {
          delete rObj?.grNumber;
          callPostApi(null, `${rUrl}`, { ...rObj }, (response) => {
            if (response.status === API_SUCCESS) {
              const responseData = response.data;
              //   handleSnack("success", "GR created successfully.");

              setRecordObj({
                ...responseData,
                deliveryType: recordObj?.deliveryType || "",
                shippingCondition: recordObj?.shippingCondition || "",
                senderPlant: recordObj?.senderPlant || "",
                receiverPlant: recordObj?.receiverPlant || "",
              });
              setRelatedPartsRecords([...responseData["grPartsQuantities"]]);
              setRecordId(responseData.grId);
              resolve(response);
            } else {
              handleSnack("info", "Something went wrong.");
              resolve(response);
            }
          });
        }
      });
    } catch (error) {}
  };

  // add selected parts to part part list
  const addSelectedPartsToPartList = () => {
    if (selectedMasterData.length !== 0) {
      const _grPartsQuantities = [...recordObj["grPartsQuantities"]];
      const rowsData = selectedMasterData.map((row) => {
        _grPartsQuantities.push({
          partsNumber: row?.partNumber,
          partsDescription: row?.partDescription,
          quantityOrdered: 0,
          quantityReceived: 0,
          grOrderAction: "EMPTY",
        });
      });

      // Using Set to filter unique partNumber
      const uniqueByPartNumber = _grPartsQuantities.filter(
        (item, index, self) => index === self.findIndex((t) => t.partsNumber === item.partsNumber)
      );

      handleAddUpdateGR(uniqueByPartNumber).then((res) => {
        hideModal();
        handleSnack("success", "Selected parts added to GR successfully.");
      });
    }
  };

  // Search table column for spareparts
  const columnsPartListSearch = [
    { headerName: "Group Number", field: "groupNumber", flex: 1, width: 70 },
    { headerName: "Type", field: "partType", flex: 1, width: 130 },
    { headerName: "Part Number", field: "partNumber", flex: 1, width: 130 },
    {
      headerName: "Description",
      field: "partDescription",
      flex: 1,
      width: 130,
    },
    { headerName: "Currency", field: "currency", flex: 1, width: 130 },
    { headerName: "Status", field: "status", flex: 1, width: 130 },
  ];
  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal-header-border">
          <Modal.Title>Search Results</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 bg-white">
          <div className="card w-100 p-2">
            <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
              <DataGrid
                sx={{ "& .MuiDataGrid-columnHeaders": { backgroundColor: "#872ff7", color: "#fff" } }}
                rows={masterData}
                columns={columnsPartListSearch}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              />
            </div>
          </div>
          <div className="m-2 text-right">
            <button className="btn text-white bg-primary mr-2" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn text-white bg-primary" onClick={addSelectedPartsToPartList}>
              + Add Selected
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GRSearchPartListModal;
