// import React from 'react'
import { Divider, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import VerticalAlignTopOutlinedIcon from "@mui/icons-material/VerticalAlignTopOutlined";
import AddIcon from "@mui/icons-material/Add";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FileUploader } from "react-drag-drop-files";
import TextField from "@mui/material/TextField";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FONT_STYLE } from "./CONSTANTS";
const fileTypes = ["XLS"];

const GRID_STYLE = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#872ff7",
    color: "white",
    fontSize: 14,
  },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    // borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
  marginInline: "auto",
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: 4,
  // height: 400,
};
const initialItemData = [
  {
    id: 1,
    item: "Ecommerce website redesign",
    rate: "$4,000",
    qty: "5",
  },
  {
    id: 2,
    item: "Logo design",
    rate: "$8,000",
    qty: "1",
  },
  {
    id: 3,
    item: "Dashboard design",
    rate: "$1,000",
    qty: "15",
  },
  {
    id: 4,
    item: "Mobile app design",
    rate: "$500",
    qty: "20",
  },
];

const InvoicePage = () => {
  const [itemData, setItemData] = useState(initialItemData);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [uploadFileImage, setUploadFileImage] = useState("");

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0, // Set to 0 to remove decimals
    maximumFractionDigits: 0,
  });

  // hide file uplaod modal
  const handleOpenCloseFileUploadModal = () => {
    setShowFileUploadModal(!showFileUploadModal);
  };
  // Image|File upload Modal box show|hide
  const handleImageFileUpload = (e, value) => {
    setUploadFileImage(value);
  };

  const handleAddNewRow = () => {
    // Add a new row with default values
    setItemData([...itemData, { item: "", rate: "", qty: "", amount: "" }]);
  };

  const columns = [
    { field: "item", headerName: "Item", width: 200 },
    { field: "rate", headerName: "Rate", width: 80 },
    { field: "qty", headerName: "Quantity", width: 75 },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      renderCell: (params) => {
        const rate = parseFloat(params.row.rate.replace(/[^0-9.-]+/g, "")) || 0;
        const qty = parseInt(params.row.qty.replace(/[^0-9.-]+/g, ""), 10) || 0;
        const amount = rate * qty; // Calculate amount

        return <span>{currencyFormatter.format(amount)}</span>; // Format the amount
      },
    },
  ];
  const handleDeleteTableRow = (id) => {
    // Filter out the row with the matching id
    const updatedData = itemData.filter((row) => row.id !== id);
    setItemData(updatedData);
  };
  const calculateTax = (subtotal) => subtotal * 0.1; // Tax is 10% of subtotal
  const calculateTotal = (subtotal, tax) => subtotal + tax;

  // Calculate subtotal by cleaning up the rate and qty
  const subtotal = itemData.reduce((sum, item) => {
    const numericRate = parseFloat(item.rate.replace(/[$,]/g, "")); // Remove $ and commas
    const numericQty = parseInt(item.qty.replace(/[$,]/g, ""), 10); // Convert qty to an integer
    return sum + numericRate * numericQty;
  }, 0);

  const tax = calculateTax(subtotal);
  const total = calculateTotal(subtotal, tax);

  // Format each value with the currency formatter
  const formattedSubtotal = currencyFormatter.format(subtotal);
  const formattedTax = currencyFormatter.format(tax);
  const formattedTotal = currencyFormatter.format(total);

  // update the field value
  const handleUpdateTableRow = (e, rowData, fieldName) => {
    const { value } = e.target;
    const updatedTableRecords = itemData.map((record) =>
      record.id === rowData?.id ? { ...record, [fieldName]: value } : record
    );
    setItemData(updatedTableRecords);
  };

  return (
    <>
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <h4 className="font-weight-600 mt-3">Invoice Details</h4>
          <Divider sx={{ marginBottom: 4 }} />{" "}
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <div className={`card px-4 py-3 text-primary cursor `}>
                <div className="row input-fields">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group mb-0">
                      <label className="text-light-dark font-size-12 font-weight-500">
                        ATTACHMENT
                      </label>
                      <div
                        className="card border px-3 py-1 cursor mb-0"
                        onClick={handleOpenCloseFileUploadModal}
                      >
                        <div className="d-flex">
                          <VerticalAlignTopOutlinedIcon
                            sx={{ fontSize: "28px" }}
                          />
                          <div className="mx-2">
                            <h5 className="mb-0 mt-0 font-size-13">
                              Upload File
                            </h5>
                            <span className="font-size-12">
                              {fileTypes.join(", ")}, less than 5MB
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">
                        Invoice Number
                      </label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="supplierCode"
                        placeholder="#002221"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">
                        Your Company Details
                      </label>
                      <textarea
                        className="form-control border-radius-10 text-primary"
                        name="company"
                        cols="30"
                        rows="2"
                        placeholder="Musemind Road 3 Block B Banashree Dhaka, Bangladesh"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">
                        Bill To
                      </label>
                      <textarea
                        className="form-control border-radius-10 text-primary"
                        name="billTo"
                        cols="30"
                        rows="2"
                        placeholder="Panther,456 Second Street Brooklyn NY 11207"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">
                        Date Issued
                      </label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                inputProps={{
                                  ...params.inputProps,
                                  style: FONT_STYLE,
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">
                        Pay Terms
                      </label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                inputProps={{
                                  ...params.inputProps,
                                  style: FONT_STYLE,
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="p-2 my-2 border-radius-10"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  <div className="d-flex align-items-center">
                    <span className="font-weight-500" style={{ width: "55%" }}>
                      Item
                    </span>
                    <span className="font-weight-500" style={{ width: "15%" }}>
                      Rate
                    </span>
                    <span className="font-weight-500" style={{ width: "10%" }}>
                      Qnt.
                    </span>
                    <span className="font-weight-500" style={{ width: "15%" }}>
                      Amount
                    </span>
                  </div>
                  {/* </div> */}
                  {itemData.map((row, i) => (
                    <>
                      <div className="d-flex my-2" key={i}>
                        <div className="pr-3" style={{ width: "55%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10"
                              name="serialNumber"
                              readOnly
                              value={row.item}
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "15%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10 text-center"
                              name="rate"
                              placeholder="Rate"
                              value={row.rate}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "10%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10 text-center"
                              name="serialNumber"
                              placeholder="Qnt."
                              value={row.qty}
                              // readOnly
                              onChange={(e) =>
                                handleUpdateTableRow(e, row, "qty")
                              }
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "15%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10"
                              name="serialNumber"
                              placeholder="Price"
                              // value={row.amount}
                              value={currencyFormatter.format(
                                // Remove $ and commas before parsing
                                (parseFloat(
                                  row.rate.replace(/[^0-9.-]+/g, "")
                                ) || 0) *
                                  (parseInt(
                                    row.qty.replace(/[^0-9.-]+/g, "")
                                  ) || 0)
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="pr-3 py-2" style={{ width: "5%" }}>
                          <span
                            className="font-size-18 font-weight-500 cursor"
                            onClick={() => handleDeleteTableRow(row.id)}
                          >
                            X
                          </span>
                        </div>
                      </div>
                    </>
                  ))}

                  <div className="d-flex justify-content-center">
                    <Tooltip title="Add More">
                      <button
                        className="btn bg-primary text-white d-flex justify-content-center align-items-center font-size-28 border-radius-50"
                        style={{ width: "50px", height: "50px" }}
                        onClick={handleAddNewRow}
                      >
                        <AddIcon />
                      </button>
                    </Tooltip>
                  </div>
                </div>
                <div className="row input-fields mt-3">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">
                        NOTES / PAYMENT TERMS
                      </label>
                      <textarea
                        className="form-control border-radius-10 text-primary"
                        name="paymentTermsNote"
                        cols="30"
                        rows="2"
                        placeholder="Payment Terms Note"
                      ></textarea>
                    </div>
                  </div>
                  {/* </div> */}
                  <div className="col-md-6 col-sm-6">
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14">Subtotal</h5>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14">{formattedSubtotal}</h5>
                      </div>
                    </div>
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 text-right">Tax</p>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 font-weight-400 border border-radius-10 p-1">
                          {" "}
                          {formattedTax}
                        </p>
                      </div>
                    </div>
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 text-right">Discount</p>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 font-weight-400 border border-radius-10 p-1">
                          $0.00
                        </p>
                      </div>
                    </div>
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 text-right">Shipping Free</p>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 font-weight-400 border border-radius-10 p-1">
                          $0.00{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-1 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14 text-primary">Total</h5>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14 text-primary">
                          {formattedTotal}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Grid>
            <Grid item xs={5}>
              <div className={`card  bg-grey px-2  cursor `}>
                <h5 className="mt-3 px-3">Preview Invoice</h5>
                <div className={`card  bg-white mt-2 p-3  cursor `}>
                  <div
                    className="d-flex justify-content-between  p-2 my-2 mt-3 border-radius-10"
                    style={{ backgroundColor: "black" }}
                  >
                    <span className="brand-title ml-2 logo-servicecpq">
                      <svg
                        style={{ width: "30%" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1299.92 258.91"
                      >
                        <defs></defs>
                        <g className="cls-4">
                          <g id="Layer_1">
                            <g>
                              <g className="cls-3">
                                <g>
                                  <path
                                    className="cls-2"
                                    d="M1160.38,215.55v-51.18c48.72,0,88.36-39.64,88.36-88.36h51.18c0,76.94-62.6,139.54-139.54,139.54Z"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="1178.24 76.01 1213.08 0 1282.98 0 1248.14 76.01 1178.24 76.01"
                                  />
                                </g>
                              </g>
                              <g>
                                <path
                                  className="cls-1"
                                  d="M18.45,216.77c-5.34-1.41-11.27-3.45-17.79-6.11v-29.13c12.01,9.04,24.6,13.56,37.8,13.56,5.04,0,8.71-.85,11.01-2.56,2.29-1.7,3.45-4.04,3.45-7,0-3.41-1.86-5.93-5.56-7.56-3.71-1.63-9.64-3.41-17.79-5.34-8.6-1.78-15.68-5.34-21.24-10.67-5.56-5.34-8.34-13.12-8.34-23.35,0-8.74,2.22-15.93,6.67-21.57,4.45-5.63,10.04-9.75,16.79-12.34,6.74-2.59,13.75-3.89,21.01-3.89,8.89,0,16.12,.78,21.68,2.33,5.56,1.56,11.01,3.97,16.34,7.23v26.02c-7.56-4.15-13.42-7.11-17.57-8.89-4.15-1.78-8.97-2.67-14.45-2.67s-9.56,.86-12.23,2.56c-2.67,1.71-4,4.11-4,7.23,0,3.71,1.48,6.41,4.45,8.12,2.96,1.71,7.71,3.45,14.23,5.23,7.41,1.93,13.27,3.82,17.57,5.67,4.3,1.86,8.15,5.04,11.56,9.56,3.41,4.52,5.11,10.86,5.11,19.01-.3,12.75-5.11,22.05-14.45,27.91-9.34,5.85-21.2,8.78-35.58,8.78-7.11,0-13.34-.71-18.68-2.11Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M212.8,167.96h-74.27c2.81,15.57,13.27,23.35,31.35,23.35,5.48,0,10.86-.78,16.12-2.34,5.26-1.56,10.86-3.59,16.79-6.11v28.46c-12.16,5.04-25.8,7.56-40.91,7.56-11.42,0-21.42-2.34-30.02-7.01-8.6-4.67-15.27-11.38-20.01-20.12-4.74-8.74-7.11-19.05-7.11-30.91,0-19.57,4.85-34.72,14.57-45.47,9.71-10.75,23.53-16.12,41.47-16.12,35.13,0,52.48,22.9,52.03,68.71Zm-74.49-21.12h41.58c-1.04-8.01-3.15-13.71-6.34-17.12-3.19-3.41-7.89-5.11-14.12-5.11-11.86,0-18.9,7.41-21.12,22.24Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M235.92,114.82c2.22-1.93,6.11-4.08,11.68-6.45,5.56-2.37,11.79-4.41,18.68-6.11,6.89-1.7,13.53-2.56,19.9-2.56,12.3,0,20.83,1.86,25.57,5.56v24.24c-5.93-1.33-14.45-2-25.57-2-6.97,0-12.68,.37-17.12,1.11v86.94h-33.13V114.82Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M448.94,103.03l-46.92,112.51h-33.13l-46.92-112.51h35.8l29.8,76.05,26.46-76.05h34.91Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M469.17,62.12h34.24v25.57h-34.24v-25.57Zm.67,42.47h33.13l.22,110.96h-33.35V104.59Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M555.67,211.99c-8.89-4.3-15.97-10.78-21.24-19.46-5.26-8.67-7.89-19.23-7.89-31.69,0-20.01,5.34-35.17,16.01-45.47,10.67-10.3,24.9-15.45,42.69-15.45,6.23,0,12.52,.63,18.9,1.89,6.37,1.26,12.01,3,16.9,5.23v28.46c-6.08-2.67-11.49-4.71-16.23-6.12-4.75-1.41-9.49-2.11-14.23-2.11-9.79,0-17.42,2.41-22.9,7.23-5.49,4.82-8.23,12.34-8.23,22.57,0,11.42,2.56,19.86,7.67,25.35,5.12,5.49,13.45,8.23,25.02,8.23,8.74,0,18.45-2.96,29.13-8.89v28.24c-5.34,2.82-10.86,4.93-16.57,6.34-5.71,1.41-12.12,2.11-19.23,2.11-10.97,0-20.9-2.15-29.8-6.45Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M746.23,167.96h-74.27c2.81,15.57,13.27,23.35,31.35,23.35,5.48,0,10.86-.78,16.12-2.34,5.26-1.56,10.86-3.59,16.79-6.11v28.46c-12.16,5.04-25.8,7.56-40.91,7.56-11.42,0-21.42-2.34-30.02-7.01-8.6-4.67-15.27-11.38-20.01-20.12-4.74-8.74-7.11-19.05-7.11-30.91,0-19.57,4.85-34.72,14.57-45.47,9.71-10.75,23.53-16.12,41.47-16.12,35.13,0,52.48,22.9,52.03,68.71Zm-74.49-21.12h41.58c-1.04-8.01-3.15-13.71-6.34-17.12-3.19-3.41-7.89-5.11-14.12-5.11-11.86,0-18.9,7.41-21.12,22.24Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M792.26,211.99c-8.89-4.3-15.97-10.78-21.24-19.46-5.26-8.67-7.89-19.23-7.89-31.69,0-20.01,5.34-35.17,16.01-45.47,10.67-10.3,24.9-15.45,42.69-15.45,6.23,0,12.52,.63,18.9,1.89,6.37,1.26,12.01,3,16.9,5.23v28.46c-6.08-2.67-11.49-4.71-16.23-6.12-4.75-1.41-9.49-2.11-14.23-2.11-9.79,0-17.42,2.41-22.9,7.23-5.49,4.82-8.23,12.34-8.23,22.57,0,11.42,2.56,19.86,7.67,25.35,5.12,5.49,13.45,8.23,25.02,8.23,8.74,0,18.45-2.96,29.13-8.89v28.24c-5.34,2.82-10.86,4.93-16.57,6.34-5.71,1.41-12.12,2.11-19.23,2.11-10.97,0-20.9-2.15-29.8-6.45Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M976.6,113.37c9.93,9.42,14.9,25.09,14.9,47.03,0,17.64-4.45,31.8-13.34,42.47-8.89,10.67-21.05,16.01-36.47,16.01-11.27,0-20.68-2-28.24-6v42.92h-32.47V126.83c0-9.04,5-15.86,15.01-20.46,10.01-4.59,22.87-6.97,38.58-7.12,18.09,0,32.09,4.71,42.03,14.12Zm-24.68,71.27c4.3-5.04,6.45-13.49,6.45-25.35,0-10.67-1.89-18.71-5.67-24.13-3.78-5.41-10.27-8.12-19.46-8.12-13.2,0-19.79,2.52-19.79,7.56v52.92c2.67,1.63,5.63,2.82,8.89,3.56,3.26,.74,6.45,1.11,9.56,1.11,9.04,0,15.71-2.52,20.01-7.56Z"
                                />
                                <path
                                  className="cls-1"
                                  d="M1118.91,131.27v127.64h-32.47v-46.7c-6.82,4.45-15.71,6.67-26.68,6.67-16.45,0-29.13-4.97-38.02-14.9-8.89-9.93-13.34-24.09-13.34-42.47,0-22.09,5.26-37.87,15.79-47.36,10.52-9.49,25.27-14.23,44.25-14.23,33.65,0,50.48,10.45,50.48,31.35Zm-70.38,53.03c4.81,5.27,11.3,7.89,19.46,7.89,7.11,0,13.27-1.56,18.46-4.67v-49.81c0-3.26-1.78-5.78-5.34-7.56-3.56-1.78-7.93-2.67-13.12-2.67-9.34,0-16.12,2.78-20.35,8.34s-6.34,13.45-6.34,23.68c0,11.27,2.41,19.53,7.23,24.79Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <div className="d-flex flex-column text-white ">
                      {" "}
                      <h7 className="  font-size-13 pr-2">Invoice No</h7>
                      <span className="font-size-12 ml-2">#002221</span>
                    </div>
                  </div>
                  <div className="my-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: "10px" }}>Summary</span>
                      <Divider
                        style={{
                          flexGrow: 1,
                          backgroundColor: "#ddd",
                          margin: 0,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-4">
                      {" "}
                      <div className="font-size-14 font-weight-500 text-black">
                        Musemind
                      </div>
                      <p className="font-size-12 font-weight-500 text-black">
                        Road 3 Block B Banashree Dhaka, Bangladesh
                      </p>{" "}
                    </div>
                    <div className="col-4">
                      <div className="font-size-14 font-weight-500 text-black">
                        Panther
                      </div>
                      <p className="font-size-12 font-weight-500 text-black">
                        456 Second Street Brooklyn NY 11207
                      </p>
                    </div>
                    <div className="col-4">
                      <div className="font-size-12 font-weight-500 text-black">
                        Date of Issue-10/04/2023<p>Pay Terms-20/06/2023</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ height: 350, width: "100%", marginTop: "30px" }}
                  >
                    <DataGrid
                      sx={GRID_STYLE}
                      rows={itemData}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5, 10, 20, 50]}
                      hideFooterSelectedRowCount
                      autoHeight
                    />
                  </div>
                  <div className=" p-2 text-right">
                    <h5 className="text-secondary font-weight-normal">
                      Subtotal:
                      <span className="font-weight-bold text-dark ml-5">
                        {formattedSubtotal}
                      </span>
                    </h5>
                    <h5 className="text-secondary font-weight-normal">
                      Tax (10%):
                      <span className="font-weight-bold text-dark ml-5">
                        {formattedTax}
                      </span>
                    </h5>
                    <h5 className="text-secondary font-weight-normal">
                      Discount:
                      <span className="font-weight-bold text-dark ml-5">
                        -$0.00
                      </span>
                    </h5>{" "}
                    <h5 className="text-secondary font-weight-normal">
                      Shipping Free:
                      <span className="font-weight-bold text-dark ml-5">
                        $0.00
                      </span>
                    </h5>
                    <Divider
                      variant="fullWidth"
                      style={{ margin: "20px 0", backgroundColor: "#ddd" }}
                    />
                    <h5 className="text-black font-weight-bold">
                      Invoice Total:
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 16,
                          color: "#872ff7",
                          marginLeft: "40px",
                        }}
                      >
                        {formattedTotal}
                      </span>
                    </h5>{" "}
                  </div>
                  <div
                    className="p-2 my-2 mt-3 border-radius-10"
                    style={{ backgroundColor: "#F2F2F2" }}
                  >
                    <div className="text-light px-3 py-2">Notes</div>
                    <p className="font-weight-bold text-dark ml-3 small">
                      Payment to be made through PayPal.Thank you for your
                      Bussiness
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-end m-2">
                  <button className="btn btn-primary">Save and Send</button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Excel File Upload */}
      <Modal
        show={showFileUploadModal}
        onHide={handleOpenCloseFileUploadModal}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Files</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="p-3">
            <div className="add-new-recod">
              <div>
                <FontAwesomeIcon
                  className="cloudupload"
                  icon={faCloudUploadAlt}
                />
                <h6 className="font-weight-500 mt-3">
                  Drag and drop files to upload <br /> or
                </h6>
                <FileUploader
                  name="file"
                  types={fileTypes}
                  handleChange={handleImageFileUpload}
                />
              </div>
            </div>
            <p className="mt-3">
              Single upload file should not be more than 10MB. Only the .lgs,
              .lgsx file types are allowed
            </p>
          </div>
        </Modal.Body>
        <div className="row m-0 p-3">
          <div className="col-md-6 col-sm-6">
            <button
              className="btn border w-100 bg-white"
              onClick={handleOpenCloseFileUploadModal}
            >
              Cancel
            </button>
          </div>
          <div className="col-md-6 col-sm-6">
            <button
              className="btn btn-primary w-100 cursor"
              onClick={handleOpenCloseFileUploadModal}
            >
              <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />{" "}
              Upload
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InvoicePage;
