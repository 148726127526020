import React, { useCallback, useEffect, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Divider, TextField, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import $ from "jquery";
import Moment from "react-moment";
import Select from "react-select";
import { Modal } from "react-bootstrap";

import { grReqObj, deliveryTypeOptions, sippingConditionOptions, plantByOptions } from "../warehouseConstents";
import { FONT_STYLE, FONT_STYLE_SELECT, GRID_STYLE, SPAREPART_SEARCH_Q_OPTIONS } from "pages/common/constants";
import SearchComponent from "pages/components/SearchComponent";
import { sparePartSearch } from "services/searchServices";
import SearchPartListModal from "pages/warrantyManagement/claimMaster/SearchPartListModal";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { WAREHOUSE_GOODS_RECEIPT_MASTER_URL } from "services/CONSTANTS";
import { API_SUCCESS } from "services/ResponseCode";
import { ReadOnlyField } from "pages/common/ReadOnlyField";
import LoadingProgress from "pages/common/Loader";
import GRSearchPartListModal from "./GRSearchPartListModal";

const AddUpdateGR = ({ show, hideModal, handleSnack, recordId = null, pendingParts = [] }) => {
  const [grRecordObj, setGrRecordObj] = useState({ ...grReqObj });
  const [viewModeOn, setViewModeOn] = useState(false);

  const [loading, setLoading] = useState(false);
  const [grId, setGrId] = useState(null);

  const [shipmentSearchSelector, setShipmentSearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  const [partsSearchData, setPartsSearchData] = useState([]);
  const [searchResultOpen, setSearchResultOpen] = useState(false);
  const [partsRecordData, setPartsRecordData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    if (recordId) {
      setGrId(recordId);
      getGRDetails(recordId);
    } else {
      setGrId(null);
      setGrRecordObj({ ...grReqObj });
    }
  }, [recordId]);

  // get the GR details
  const getGRDetails = async (recordId) => {
    setViewModeOn(true);
    setLoading(true);
    const rUrl = `${WAREHOUSE_GOODS_RECEIPT_MASTER_URL}/${recordId}`;
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          // find the delivery type
          const _deliveryType = deliveryTypeOptions.find((item) => item.value === responseData?.deliveryType);

          // find the shipping condition
          const _shippingCondition = sippingConditionOptions.find((item) => item.value === responseData?.shippingCondition);

          // find the sender plant
          const _senderPlant = plantByOptions.find((item) => item.value === responseData?.senderPlant);

          // find the receiver plant
          const _receiverPlant = plantByOptions.find((item) => item.value === responseData?.receiverPlant);

          setGrRecordObj({
            ...responseData,
            senderPlant: _senderPlant || "",
            deliveryType: _deliveryType || "",
            shippingCondition: _shippingCondition || "",
            receiverPlant: _receiverPlant || "",
          });

          setPartsRecordData(responseData["grPartsQuantities"]);

          setGrId(responseData.grId);
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
        handleSnack("error", "Error fetching STO details");
      }
    );
  };

  // change the input text value
  const handleChangeInputText = (e) => {
    const { name, value } = e.target;
    setGrRecordObj({ ...grRecordObj, [name]: value });
  };

  // change the select option
  const handleSelectValueChange = (e, keyName) => {
    setGrRecordObj({ ...grRecordObj, [keyName]: e });
  };

  // Consumable Search
  const handlePartsSearchClick = async (type, shipmentSearch = false) => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    const tempSearchSelector = [...shipmentSearchSelector];
    tempSearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const res = await sparePartSearch(searchStr);
        setPartsSearchData(res);
        // setIsShipmentSearch(shipmentSearch);
        setSearchResultOpen(true);
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // Close SparePart search modal
  const handleSearchResClose = () => {
    setSearchResultOpen(false);
    // setSelectedMasterData([]);
  };

  // click on Submit button >> Old
  // const handleSubmit = () => {
  //   const rUrl = WAREHOUSE_GOODS_RECEIPT_MASTER_URL;
  //   const _shipmentParts = partsRecordData.map((row) => row.shipmentPartId);
  //   const rObj = {
  //     ...grRecordObj,
  //     rmaReason: grRecordObj?.referenceDescription,
  //     rmaType: grRecordObj.orderNumber,
  //     rmaNumber: grRecordObj?.referenceId,
  //     returnType: grRecordObj.shippingCondition?.value || "INTRA_COMPANY",
  //     senderLocation: grRecordObj?.senderStorageLocation,
  //     senderPlant: grRecordObj?.senderPlant?.value || "",
  //     shipmentParts: [..._shipmentParts],
  //   };

  //   // deliveryType: _deliveryType || deliveryTypeOptions[0],

  //   if (grId) {
  //     callPutApi(null, `${rUrl}/${grId}`, rObj, (response) => {
  //       if (response.status === API_SUCCESS) {
  //         handleSnack("success", "GR updated successfully.");
  //         setViewModeOn(true);
  //       } else {
  //         handleSnack("info", "Something went wrong.");
  //       }
  //     });
  //   } else {
  //     const _stoNumber = "SO" + (Math.floor(Math.random() * 9000) + 1000);
  //     callPostApi(null, `${rUrl}`, { ...rObj, returnNumber: _stoNumber }, (response) => {
  //       if (response.status === API_SUCCESS) {
  //         const responseData = response.data;
  //         handleSnack("success", "GR created successfully.");
  //         // handleSnack("info", "STO accepted in ERP & Updated in GR.");
  //         setGrRecordObj({ ...grRecordObj, stoNumber: responseData._stoNumber });
  //         setGrId(responseData.grId);
  //         setViewModeOn(true);
  //       } else {
  //         handleSnack("info", "Something went wrong.");
  //       }
  //     });
  //   }
  // };

  // click on Submit button
  const handleSubmit = () => {
    const rUrl = WAREHOUSE_GOODS_RECEIPT_MASTER_URL;
    const rObj = {
      ...grRecordObj,
      deliveryType: grRecordObj?.deliveryType?.value || "",
      shippingCondition: grRecordObj?.shippingCondition?.value || "",
      senderPlant: grRecordObj?.senderPlant?.value || "",
      receiverPlant: grRecordObj?.receiverPlant?.value || "",
    };
    if (grId) {
      callPutApi(null, `${rUrl}/${grId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          handleSnack("success", "GR updated successfully.");
          setViewModeOn(true);
        } else {
          handleSnack("info", "Something went wrong.");
        }
      });
    } else {
      const _stoNumber = "SO" + (Math.floor(Math.random() * 9000) + 1000);
      callPostApi(null, `${rUrl}`, { ...rObj, returnNumber: _stoNumber }, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleSnack("success", "GR created successfully.");
          // handleSnack("info", "STO accepted in ERP & Updated in GR.");
          setGrRecordObj({ ...grRecordObj, stoNumber: responseData._stoNumber });
          setGrId(responseData.grId);
          setViewModeOn(true);
        } else {
          handleSnack("info", "Something went wrong.");
        }
      });
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // click on row edit button
  const handleEditClick = (grPartsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [grPartsId]: { mode: GridRowModes.Edit },
    });
  };

  // click on row save button
  const handleSaveClick = (grPartsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [grPartsId]: { mode: GridRowModes.View },
    });
  };

  // click on row cancel button
  const handleCancelClick = (grPartsId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [grPartsId]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = partsRecordData.find((row) => row.grPartsId === grPartsId);
    if (editedRow.isNew) {
      setPartsRecordData(partsRecordData.filter((row) => row.grPartsId !== grPartsId));
    }
  };

  // row update process >> put api call
  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const updatedRow = {
          ...newRow,
          quantityOrdered: Number(newRow?.quantityOrdered || 0),
          quantityReceived: Number(newRow?.quantityReceived || 0),
          isNew: true,
        };

        const _partsRecordData = partsRecordData.map((row) => (row.grPartsId === newRow?.grPartsId ? { ...row, ...updatedRow } : row));

        setPartsRecordData(partsRecordData.map((row) => (row.grPartsId === newRow?.grPartsId ? { ...updatedRow } : row)));

        const rUrl = WAREHOUSE_GOODS_RECEIPT_MASTER_URL;
        const rObj = {
          ...grRecordObj,
          deliveryType: grRecordObj?.deliveryType?.value || "",
          shippingCondition: grRecordObj?.shippingCondition?.value || "",
          senderPlant: grRecordObj?.senderPlant?.value || "",
          receiverPlant: grRecordObj?.receiverPlant?.value || "",
          grPartsQuantities: [..._partsRecordData],
        };

        if (grId) {
          callPutApi(null, `${rUrl}/${grId}`, rObj, (response) => {
            if (response.status === API_SUCCESS) {
              // handleSnack("success", "GR updated successfully.");
            } else {
              // handleSnack("info", "Something went wrong.");
            }
          });
        } else {
          const _stoNumber = "SO" + (Math.floor(Math.random() * 9000) + 1000);
          callPostApi(null, `${rUrl}`, { ...rObj, returnNumber: _stoNumber }, (response) => {
            if (response.status === API_SUCCESS) {
              const responseData = response.data;

              setGrRecordObj({ ...grRecordObj });
              setGrId(responseData.grId);
              setViewModeOn(true);
            } else {
              handleSnack("info", "Something went wrong.");
            }
          });
        }

        // if (newRow.grPartsId) {
        //   callPutApi(null, `${SHIPMENT_PARTS_MASTER_URL}/${newRow.shipmentPartId}`, updatedRow, (response) => {
        //     if (response.status === API_SUCCESS) {
        //       handleSnack("success", "Parts updated successfully");
        //       setPartsRecordData(
        //         partsRecordData.map((row) => (row.shipmentPartId === updatedRow.shipmentPartId ? { ...updatedRow, isNew: undefined } : row))
        //       );
        //       resolve(response.data);
        //     } else {
        //       handleSnack("error", "Parts details could not be updated");
        //       resolve(oldRow);
        //     }
        //   });
        // } else {
        //   callPostApi(null, `${SHIPMENT_PARTS_MASTER_URL}`, updatedRow, (response) => {
        //     if (response.status === API_SUCCESS) {
        //       const responseData = response.data;
        //       handleSnack("success", "Parts Created successfully");
        //       setPartsRecordData(
        //         partsRecordData.map((row) =>
        //           row.shipmentPartId === updatedRow.shipmentPartId
        //             ? { ...updatedRow, shipmentPartId: responseData.shipmentPartId, isNew: undefined }
        //             : row
        //         )
        //       );
        //       resolve(response.data);
        //     } else {
        //       handleSnack("error", "Parts details could not be updated");
        //       resolve(oldRow);
        //     }
        //   });
        // }

        resolve(updatedRow);
      }),
    [partsRecordData]
  );

  const columns = [
    {
      field: "partsNumber",
      headerName: "Part Number",
      flex: 1,
    },
    {
      field: "partsDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "quantityOrdered",
      headerName: "Quantity Ordered",
      flex: 1,
      editable: true,
    },
    {
      field: "quantityReceived",
      headerName: "Quantity Received",
      flex: 1,
      editable: true,
      // type: "singleSelect",
      // valueOptions: [
      //   { label: "All Parts", value: "ALL_PARTS" },
      //   { label: "Received Partial", value: "RECEIVED_PARTIAL" },
      //   { label: "No Parts", value: "NO_PARTS" },
      // ],
      // valueFormatter: ({ api, field, value }) => {
      //   const options = api.getColumn(field).valueOptions;
      //   const option = options.find(({ value: optionValue }) => value === optionValue);

      //   if (option) return option.label;
      // },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.grPartsId]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(row.grPartsId)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.grPartsId)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditOutlinedIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.grPartsId)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={
          //     <Tooltip title="Remove">
          //       <DeleteIcon />
          //     </Tooltip>
          //   }
          //   label="Delete"
          //   onClick={handleDeleteClick(row.shipmentPartId)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        {loading ? (
          <LoadingProgress />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center px-3 my-2">
              <h4 className="font-weight-500">Goods Receipt Details</h4>
              {grId && (
                <button className="btn border-primary text-primary py-2" onClick={() => setViewModeOn(false)}>
                  Edit
                </button>
              )}
            </div>
            <Divider />
            <Modal.Body>
              <div className="card border px-3 py-2">
                {viewModeOn ? (
                  <div className="row mt-2">
                    <ReadOnlyField label="STO NUMBER" value={grRecordObj?.stoNumber} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="ORDER NUMBER" value={grRecordObj?.orderNumber} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="REFERENCE DESCRIPTION" value={grRecordObj?.referenceDescription} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="DELIVERY TYPE" value={grRecordObj?.deliveryType?.label} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="SHIPPPING CONDITION" value={grRecordObj?.shippingCondition?.label} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="SENDER PLANT" value={grRecordObj?.senderPlant?.label} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="SENDER STORAGE LOCATION" value={grRecordObj?.senderStorageLocation} className="col-md-3 col-sm-3" />
                    <ReadOnlyField
                      label="SHIPPED ON"
                      value={grRecordObj?.shippedOn ? <Moment format="DD/MM/YYYY">{grRecordObj?.shippedOn}</Moment> : "NA"}
                      className="col-md-3 col-sm-3"
                    />
                    <ReadOnlyField label="TRACKING NUMBER" value={grRecordObj?.trackingNumber} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="RECEIVER PLANT" value={grRecordObj?.receiverPlant?.label} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="RECEIVER LOCATION" value={grRecordObj?.receiverLocation} className="col-md-3 col-sm-3" />
                    <ReadOnlyField label="RECEIVER ADDRESS" value={grRecordObj?.receiverAddress} className="col-md-3 col-sm-3" />
                  </div>
                ) : (
                  <div className="row input-fields">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">STO NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={grRecordObj?.stoNumber}
                          name="stoNumber"
                          placeholder="STO Number"
                          disabled
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ORDER NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={grRecordObj?.orderNumber}
                          name="orderNumber"
                          placeholder="Order Number"
                          disabled
                          //   onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">REFERENCE DESCRIPTION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={grRecordObj?.referenceDescription}
                          name="referenceDescription"
                          placeholder="Reference Description"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">DELIVERY TYPE</label>
                        <Select
                          className="text-primary"
                          value={grRecordObj?.deliveryType}
                          onChange={(e) => handleSelectValueChange(e, "deliveryType")}
                          options={deliveryTypeOptions}
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SHIPPPING CONDITION</label>
                        <Select
                          className="text-primary"
                          value={grRecordObj?.shippingCondition}
                          onChange={(e) => handleSelectValueChange(e, "shippingCondition")}
                          options={sippingConditionOptions}
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SENDER PLANT</label>
                        <Select
                          className="text-primary"
                          value={grRecordObj?.senderPlant}
                          onChange={(e) => handleSelectValueChange(e, "senderPlant")}
                          options={plantByOptions}
                          styles={FONT_STYLE_SELECT}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SENDER STORAGE LOCATION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={grRecordObj?.senderStorageLocation}
                          name="senderStorageLocation"
                          placeholder="Sender Storage Location"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SHIPPED ON</label>
                        <div className="align-items-center date-box">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                              inputFormat="dd/MM/yyyy"
                              className="form-controldate border-radius-10"
                              closeOnSelect
                              value={grRecordObj?.shippedOn}
                              onChange={(e) => setGrRecordObj({ ...grRecordObj, shippedOn: e })}
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">TRACKING NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={grRecordObj?.trackingNumber}
                          name="trackingNumber"
                          placeholder="Tracking Number"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">RECEIVER PLANT</label>
                        <Select
                          className="text-primary"
                          value={grRecordObj?.receiverPlant}
                          onChange={(e) => handleSelectValueChange(e, "receiverPlant")}
                          options={plantByOptions}
                          styles={{
                            ...FONT_STYLE_SELECT,
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">RECEIVER LOCATION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={grRecordObj?.receiverLocation}
                          name="receiverLocation"
                          placeholder="Receiver Location"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">RECEIVER ADDRESS</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={grRecordObj?.receiverAddress}
                          name="receiverAddress"
                          placeholder="Receiver Address"
                          onChange={handleChangeInputText}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="card border px-3 py-2">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex mr-3 col-auto pl-0" style={{ whiteSpace: "pre" }}>
                        <h5 className="mr-2 mb-0 text-black">
                          <span>Products</span>
                        </h5>
                      </div>
                      <SearchComponent
                        querySearchSelector={shipmentSearchSelector}
                        setQuerySearchSelector={setShipmentSearchSelector}
                        // clearFilteredData={clearFilteredData}
                        handleSnack={handleSnack}
                        searchAPI={sparePartSearch}
                        searchClick={handlePartsSearchClick}
                        options={SPAREPART_SEARCH_Q_OPTIONS}
                        background={"white"}
                        type=""
                        buttonText="ADD PART"
                        isShipmentSearch={true}
                      />
                    </div>
                  </div>
                </div>
                <DataGrid
                  sx={GRID_STYLE}
                  getRowId={(row) => row.grPartsId}
                  rows={partsRecordData}
                  autoHeight
                  columns={columns}
                  editMode="row"
                  rowsPerPageOptions={[5, 10, 20]}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStart={handleRowEditStart}
                  onRowEditStop={handleRowEditStop}
                  experimentalFeatures={{ newEditingApi: true }}
                  onProcessRowUpdateError={(error) => console.log(error)}
                  processRowUpdate={processRowUpdate}
                  pagination
                />
              </div>
              <div className="row px-2" style={{ justifyContent: "right" }}>
                <button className={`btn border-primary text-primary mx-2`} onClick={hideModal}>
                  Close
                </button>
                {!viewModeOn && (
                  <button className="btn bg-primary text-white mx-2" onClick={handleSubmit}>
                    Submit
                  </button>
                )}
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>

      {searchResultOpen && (
        <GRSearchPartListModal
          show={searchResultOpen}
          hideModal={handleSearchResClose}
          masterData={partsSearchData}
          setRelatedPartsRecords={setPartsRecordData}
          isShipmentSearch={false}
          handleSnack={handleSnack}
          recordId={grId}
          setRecordId={setGrId}
          recordObj={grRecordObj}
          setRecordObj={setGrRecordObj}
        />
      )}
    </>
  );
};

export default AddUpdateGR;
